<template>
  <div class="mobileAccre">
    <!-- 手机号授权弹框 -->
    <div class="shadowBox" @click.stop="">
      <div class="box_content">
        <div class="content_title">
          <img src="./img/logo.png" alt="" />
          <span class="appName">小邕钱包</span>
          <span>申请</span>
        </div>
        <div class="content_text">
          <p class="getPhone">获取您的手机号</p>
          <p class="grayText">方便您一键注册登录</p>
        </div>
        <div class="userPhone">{{ cellPhone }}</div>
        <div class="twoBtn">
          <p @click="refuse">拒绝</p>
          <p @click="aggrement">允许</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, onUnmounted, defineEmits } from "vue";
// import APP from "@/utils/App";
import API from "@/server/api.js";

export default {
  setup(props, { emit }) {
    const state = reactive({
      cellPhone: "",
    });
    // const emit = defineEmits(['changeStock']);
    const userPhone = localStorage.getItem("cellPhone");
    state.cellPhone = userPhone.replace(/^(\d{3})\d{4}(\d{4})$/, "$1****$2");
    console.log(state.cellPhone, "手机号");
    //点击拒绝返回上一页
    const refuse = () => {
      APP.Back();
    };
    //点击允许
    const aggrement = async () => {
      const res = await API.registerPhone({ phone: userPhone, channel:'BLMX_H5' });
      console.log(res, "0000");
      if (res.code == 200) {
        localStorage.setItem("stock", 1);
        emit("changeStock", { stock: 1 });
      }
    };
    // onMounted(()=>{
    //     state.cellPhone=localStorage.getItem('cellPhone');
    // })
    return {
      ...toRefs(state),
      refuse,
      aggrement,
    };
  },
};
</script>

<style scoped>
p {
  margin: 0;
  padding: 0;
}
.mobileAccre {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
}
.shadowBox {
  background: #fff;
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 619px;
  border-radius: 36px 36px 0px 0px;
}
.box_content {
  padding: 42px 46px 66px 46px;
  box-sizing: border-box;
}
.box_content .content_title {
  display: flex;
  align-items: center;
}
.box_content .content_title img {
  width: 72px;
  height: 72px;
  margin-top: 5px;
}

.box_content .content_title span {
  font-size: 38px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #1e1e1e;
}
.box_content .content_title span.appName {
  margin: 0 20px;
}
.box_content .content_text {
  height: 190px;
  padding: 49px 0 38px 0;
  box-sizing: border-box;
  border-bottom: 1px solid #f0f0f0;
}
.box_content .content_text .getPhone {
  font-size: 34px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #1e1e1e;
  margin-bottom: 18px;
}
.box_content .content_text .grayText {
  font-size: 26px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #9b9b9b;
}
.box_content .userPhone {
  height: 123px;
  display: flex;
  align-items: center;
  font-size: 38px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #1e1e1e;
  border-bottom: 1px solid #f0f0f0;
}
.box_content .twoBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.box_content .twoBtn p {
  width: 315px;
  height: 92px;
  border-radius: 46px;
  line-height: 92px;
  text-align: center;
  margin-top: 51px;
}
.box_content .twoBtn p:first-child {
  background: #ffffff;
  border: 1px solid #FD6246;
  font-size: 32px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FD6246;
}
.box_content .twoBtn p:last-child {
  background: linear-gradient(90deg, #FD6246 0%, #FF9C65 100%);
  font-size: 32px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
</style>