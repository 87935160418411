<template>
  <van-pull-refresh
    v-model="isLoading"
    success-text="刷新成功"
    @refresh="onRefresh"
  >
    <!-- <van-nav-bar safe-area-inset-top /> -->
    <div class='new-pages'>
      <div class="new-box-s">
        <!-- 头部 -->
        <div class="new-header">
            <div class="backLast" @click="backLast">
                <van-icon name="arrow-left" size="30" />
            </div>
            <div class="my-icon">
                <img @click="toMy" src="./img/my.png" alt="" />
            </div>
        </div>
        <!-- banner -->
        <!-- <div class='new-banner'>
            <img src="./img/bgc.png" alt="" />
        </div> -->
         <!-- banner -->
        <div class="i-banner">
            <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" >
                <van-swipe-item v-for="item in bannerList"  :key="item.id" @click="gobanner(item)">
                  <van-image class="banner-img"  :src="item.url"></van-image>
                </van-swipe-item>
                
            </van-swipe>
        </div>

      </div>
       
        <!-- 卡片 -->
        <div class='new-card'>
            <div class="card-main">
              <div class="card-text" v-if="creditState != 0">最高可借(元)</div>
              <div>
                <div class="card-num" v-if="creditState == -1 || creditState == 1">200,000.00</div>
                <div  v-else>
                  <div class="card-num" v-if="creditState != 0">{{ $filters.formatMoney(loanAmount, 2) }}</div>
                  <div class="card-num" v-else></div>
                </div>
                
              </div>
              <div>
                  <div class="card-btn-dis"  v-if=" creditState == 3|| creditState == 2|| creditState == 6">
                      {{stateStr}}
                  </div>
                  <div class="card-btn" v-else  @click="goapply">
                      {{stateStr}}
                  </div>
              </div>
               
            </div>
        </div>
        <!-- 账单 -->
        <div class='new-bill' >
            <!-- 存在账单 -->
            <div class='bill-card' v-if="otherInfo!=null">
                <div class='card-main'>
                    <div class='main-header'><span>{{ otherInfo.date }}</span> 需还款(元)</div>
                    <div class='mian-line'>
                        <div class='line-l'>{{ $filters.formatMoney(otherInfo.amount, 2) }}</div>
                        <div class='line-r' @click="Payment()">
                            <span>去还款</span>
                            <van-icon class="arrow" name="arrow" />
                        </div>
                    </div>
                </div>
            </div>
            <!-- 无账单 -->
            <div class='bill-null' v-else>
                <div class='null-text'>账单金额(元)</div>
                <div class='null-text-s'>暂无借款</div>
            </div>
        </div>
        <!--签署协议弹框-->
        <template v-if="seeDialog">
            <Agree-popup
            :isShow="seeDialog"
            @changeStatus="changeStatus"
            ></Agree-popup>
        </template>
        <!-- 授权手机号弹框 -->
        <template v-if="stock == -1">
            <Mobile-accre @changeStock="changeStocks"></Mobile-accre>
        </template>
    </div>
  </van-pull-refresh>
</template>

<script>
import { reactive, toRefs, onMounted, onUnmounted } from "vue";
import API from "@/server/api.js";
import { routerTag } from "@/utils";
import { Toast,Dialog } from "vant";
import { Jump } from '@/utils/routh'
import AgreePopup from "../../components/AgreePopup/index.vue";
import MobileAccre from "../../components/MobileAccre/index.vue";
export default {
  components: { AgreePopup, MobileAccre },
  
  setup() {
    onUnmounted(() => {
      try {
        document.removeEventListener("ScanEvent", callBack, true);
      } catch (err) {
        console.log(err, "移除事件那啥");
      }
    });
    const state = reactive({
      stock: null, //用户手机号授权
      seeDialog: false,
      creditState: null,
      creditAmt: "",
      creditNo: "",
      userPhone: "",
      isLoading: false,
      protocolNeed: null,
      loanAmount: "",
      backdate: "",
      amount: "",
      loanNo: "",
      isWithdrawal: null,
      protocolList: [], // 协议列表
      otherInfo: null,
      stateStr:'',//btn按钮
      realNameState:0,//是否实名
      bannerList:[]
    });
    // 返回到当前页面的时候更新数据
    // useReload(() => {
    //   console.log("调用");
    //   userInfo();
    //   signProtocol();
    // });
    //子传父（协议弹框传过来的seeDialog）
    const changeStatus = (val) => {
      console.log(val, "子组件");
      state.seeDialog = val.seeDialog;
    };
    //授权手机号传值
    const changeStocks = (val) => {
      console.log(val, "子组件");
      state.stock = val.stock;
    };
    // 刷新
    const onRefresh = () => {
      state.isLoading = false;
      userInfo();
    };
    const gotoPage = () => {
      //第一次，没有签署协议
      if (state.protocolNeed) {

        console.log(state.protocolNeed,'protocolResult22')
        state.seeDialog = true;
      } else {
        //签署过了，直接跳到身份认证
        console.log(state.protocolNeed,'protocolResult666')
        Jump(`${routerTag}auth`);
      }
    };
    // 是否需要签署协议
    const signProtocol = async () => {
      const res = await API.issignProtocol({
        phone: state.userPhone,
        channel:'BLMX_H5'
      });
      const {result }=res
      console.log(result,'xieyi') 
      state.protocolNeed = result.need;
    };

    // 监听扫一扫的回调
    async function callBack(res) {
      const hasScan = localStorage.getItem("indexScan");
      if (hasScan !== null && hasScan === "true") return;
      if (res) {
        const result = JSON.parse(res.data);
        const data = {};
        data.bdNo = result.bd_no;
        data.partnerNo = result.partnerNo;
        data.partnerName = result.partnerName;
        data.productName = state.productName;
        data.userId = state.userId;
        await API.scanBd(data);
        localStorage.setItem("indexScan", "true");
        Toast.success("识别成功");
        await userInfo();
        document.removeEventListener("ScanEvent", callBack, true);
      } else {
        Toast.fail("扫码信息获取失败");
      }
    }
    // 轮播
    const gobanner=(item)=>{
      console.log(item.content,'item88')
      Jump(item.content);
    }
    // 返回上一层
    const backLast = () => {
      //回到app
      APP.Back();
    };
    // 去我的页面
    const toMy = () => {
      Jump(`${routerTag}myself`);
    };
    // 去还款-账单详情 6
    const Payment = () => {
      // console.log(111111);
      const loanNo= localStorage.getItem("loanNo");
      Jump(`${routerTag}billDetail?loanNo=${loanNo}`);
    };
    const userInfo = async () => {
      const res = await API.isUser({channel:'BLMX_H5'});
      // console.log(res, "90909");
      if (res.code == 200) {
        const {
          userId,
          creditNo,
          otherInfo,
          creditState,
          isWithdrawal,
          creditAmt,
          loanNo,
          loanModelList,
          productName,
          stateStr,
          realNameState,
          preCreditNo,
          bannerUrl
        } = res.result;
        console.log(bannerUrl,'bannerUrl')
        // realNameState; 0 未实名 , 1 实名
        localStorage.setItem("userId", userId);
        localStorage.setItem("creditNo", creditNo);
        localStorage.setItem('preCreditNo',preCreditNo)
        state.loanAmount = creditAmt;
        state.stateStr=stateStr
        if(realNameState!=null){
          state.realNameState=realNameState
        }
        if(otherInfo.date){
          console.log(otherInfo,'876544443434')
          state.otherInfo = otherInfo;
        }

        state.bannerList=bannerUrl
        
        if(loanModelList.length>0){
          loanModelList.map((item)=>{
              console.log(item.loanNo,'876544443434') 
              localStorage.setItem("loanNo", item.loanNo);  
          })
        }
       
        state.creditState = creditState;
        state.isWithdrawal = isWithdrawal;
        state.creditAmt = creditAmt;
        state.productName = productName;
        if (creditState === 0) {
          localStorage.setItem("indexScan", "false");
        }
      }
    };
    const goapply=()=>{
      
      const {realNameState,protocolNeed}=state
      console.log(realNameState,protocolNeed,'6666')
      if (protocolNeed) {
        //弹窗未签订的协议
        state.seeDialog = true;
      } else {
        //签署过了，直接跳到身份认证
        if(realNameState==0){
          console.log('去实名')
          //去实名
          Jump(`${routerTag}auth`);
        }else{
          //0 预授信 ，1 去授信，2 去别家看看，3 授信审核中，4 立即借款，5 去还款，6 放款审核中
          switch(state.creditState){
            case 0:
              console.log('预授信')
              scancode()
              break;
            case 1:
              localStorage.setItem('keyId',1)
              Jump(`${routerTag}infoNavigation?key=1`);
              break;
            case 4:
              Jump(`${routerTag}loan`)
              break;
            case 5:
              const loanNo= localStorage.getItem("loanNo");
              // console.log(loanNo,'999')
              Jump(`${routerTag}billDetail?loanNo=${loanNo}`);
              break;
          }
        }
      }  
    };
    const scancode=()=>{
      APP.ScanCode(async function(res){
        console.log(res,'s')
        const hasScan = localStorage.getItem("indexScan");
        if (hasScan !== null && hasScan === "true") return;
        if (res) {
          console.log(res,'6785')
          const newRes = JSON.parse(res);
          console.log(newRes,'6786')
          const data = {};
          data.bdNo = newRes.bd_no;
          data.partnerNo = newRes.partnerNo;
          data.partnerName = newRes.partnerName;
          data.productName = state.productName;
          data.userId = state.userId;
          // console.log('6787')
          await API.scanBd(data);
          localStorage.setItem("indexScan", "true");
          Toast.success("识别成功");
          await userInfo();
        } else {
          Toast.fail("扫码信息获取失败");
        }
      })
    };
    onMounted(async () => {
      state.userPhone = localStorage.getItem("cellPhone");
      state.stock = localStorage.getItem("stock");
      await userInfo(); // 初始化信息
      await signProtocol(); // 是否展示协议
      APP.GetLocation((res)=>{
        localStorage.setItem('locationInfo',res) 
      })
      // APP.GetDeviceInfo((res)=>{
      //   const {device}=JSON.parse(res)
      //   console.log(device,'设备信息')
      //   localStorage.setItem('deviceType',device)
      // })
      APP.GetDeviceInfo((res)=>{
        const ress=JSON.parse(res)
        const newVersion=ress.appVersion.replace(/\./g, "")
        console.log(ress,'设备信息')
        const {device}=ress
        localStorage.setItem('deviceType',device)
        // //添加版本更新的代码
        //  if(newVersion<104){
        //     Dialog.confirm({
        //       title: "版本更新",
        //       message: "app版本更新啦！快去更新吧",
        //       showCancelButton:false
        //     }).then(() => {
        //       if(device=='ios'){
        //         window.location.replace('https://apps.apple.com/cn/app/%E7%99%BE%E7%81%B5%E7%BE%8E%E9%80%89/id1670257906')
        //       }else{
        //         window.location.replace('https://jyd.bailingmx.com/android/blmx.apk')
        //       }
                
        //         // state.showDialog = false;
        //     }).catch(() => {
        //         APP.Back(4)
        //         console.log('取消')
        //         // on cancel
        //     });
        //  }
      })
     
    });
    return {
      ...toRefs(state),
      gotoPage,
      toMy,
      Payment,
      onRefresh,
      backLast,
      changeStatus,
      changeStocks, 
      goapply,
      gobanner
    };
  },
};
</script>

<style lang="less" src="./newpages.less" scoped>
</style>

